import {Route, Routes} from "react-router-dom";
import Lobby from "../Lobby"
import TTTBoard from "./TTTBoard";
import JoinCreate from "../JoinCreate";
import Winner from "../Winner";
import {useEffect, useState} from "react";
const io = require("socket.io-client")

export default function TTT() {
    const gameName = "Tic Tac Toe"
    const instruction = "Both players alternately put their sign into an empty field on the board. The goal is to place your own sign three times in a row, in a column or in a diagonal. The first player to succeed wins."
    const lobbyLink = "https://minigamecollection.de/ttt?lobbyid="

    const [socket] = useState(io.connect("https://minigamecollection.de:3000/tictactoe"))

    useEffect(() => {

        socket.on("connect", () => {
            console.log("Connected to id: " + socket.id)
        })
        console.log(socket)

        socket.on("basicEmit", (message) => {
            console.log("basicEmit: " + message)
        })

        return () => {
            socket.disconnect()
        }
    }, [])

    return(
        <div>
            <Routes>
                <Route path="/" element={<JoinCreate socket={socket} gameName={gameName} />} />
                <Route path="/lobby"
                       element={<Lobby
                           socket={socket}
                           gameName={gameName}
                           instruction={instruction}
                           startLobbyLink={lobbyLink} />}
                />
                <Route path="/board" element={<TTTBoard socket={socket} /> } />
                <Route path="/winner" element={<Winner socket={socket} /> }/>
            </Routes>
        </div>
    )
}