import {Route, Routes} from "react-router-dom";
import Lobby from "../Lobby"
import SSBoard from "./SSBoard";
import JoinCreate from "../JoinCreate";
import Winner from "../Winner";
import {useEffect} from "react";
const io = require("socket.io-client")

export default function TTT() {
    const gameName = "Simon Says"
    const instruction = "The game is played alternately. " +
        "In each round, the active player must complete the sequence created by both players. " +
        "If the sequence is completed correctly, the player adds another step. \n" +
        "If an incorrect square is selected, the player loses a life and " +
        "has the option to re-select another square from the same position in the sequence"
    const lobbyLink = "https://minigamecollection.de/simon-says?lobbyid="

    let socket = io.connect("https://minigamecollection.de:3000/simon-says")

    useEffect(() => {
        console.log("Socketconnection on!")
        socket.on("connect", () => {
            console.log("Connected to id: " + socket.id)
        })
        console.log(socket)

        socket.on("basicEmit", (message) => {
            console.log("basicEmit: " + message)
        })

        return () => {
            socket.disconnect()
        }
    }, [])

    return(
        <div>
            <Routes>
                <Route path="/" element={<JoinCreate socket={socket} gameName={gameName}/>} />
                <Route path="/lobby" element={<Lobby
                    socket={socket}
                    gameName={gameName}
                    instruction={instruction}
                    startLobbyLink={lobbyLink} />}
                />
                <Route path="/board" element={<SSBoard socket={socket} />} />
                <Route path="/winner" element={<Winner socket={socket} />} />
            </Routes>
        </div>
    )
}