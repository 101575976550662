import { createContext, useReducer } from "react";
import AppReducer from "./AppReducer"

const initialState = {
    name: "",
    lobbyId: "",
    gameBoard: [],
    winner: null,
    firstPlayer: null,
    players: {}
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({children}) => {
    const [state, dispatch] = useReducer(AppReducer, initialState)

    function namePlayer(name) {
        dispatch({
            type: "NAME_PLAYER",
            payload: name
        })
    }

    function saveLobbyId(lobbyId) {
        dispatch({
            type: "SAVE_LOBBY_ID",
            payload: lobbyId
        })
    }

    function saveGameBoard(gameBoard) {
        dispatch({
            type: "SAVE_GAME_BOARD",
            payload: gameBoard
        })
    }

    function saveWinner(winner) {
        dispatch({
            type: "SAVE_WINNER",
            payload: winner
        })
    }

    function saveFirstPlayer(firstPlayer) {
        dispatch({
            type: "SAVE_FIRST_PLAYER",
            payload: firstPlayer
        })
    }

    function savePlayers(players) {
        dispatch({
            type: "SAVE_PLAYERS",
            payload: players
        })
    }

    return(
        <GlobalContext.Provider value={
            {
                name: state.name, namePlayer,
                gameBoard: state.gameBoard, saveGameBoard,
                lobbyId: state.lobbyId, saveLobbyId,
                winner: state.winner, saveWinner,
                firstPlayer: state.firstPlayer, saveFirstPlayer,
                players: state.players, savePlayers
            }
        }>
            {children}
        </GlobalContext.Provider>
    )
}