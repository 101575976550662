export default function (props) {
    return(
        <button
            id={props.id}
            onClick={props.onClick}
            className={props.className}
            y={props.y}
            x={props.x}
            isturned={props.isturned}
        >
            {props.name}
        </button>
    )
}