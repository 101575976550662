import "../css/Lobby.css"
import "../css/Popup.css"
import Button from "./Button";
import {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {GlobalContext} from "../context/GlobalState";
import QRCode from "react-qr-code";
import Popup from "reactjs-popup";
import { showMessage } from "../message";

export default function Lobby(props) {

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const { name,
            lobbyId,
            saveLobbyId,
            saveGameBoard,
            saveFirstPlayer,
            savePlayers } = useContext(GlobalContext)

    const socket = props.socket

    const [player1, setPlayer1] = useState(null)
    const [player2, setPlayer2] = useState(null)
    const [ready, setReady] = useState("Are you ready?")

    const [lobbyLink, setLobbyLink] = useState("")

    const instruction = props.instruction

    const handleCopyRoomIDClick = (event) => {
        const copy = lobbyId
        navigator.clipboard.writeText(copy).then( () => {
            console.log("Clipboard succesfully set")
        }, () => {
            console.log("Clipboard write failed")
        })
        showMessage("Copied Room-ID", "rightCondition")
            .then(r => console.log("Copied Room-ID"))
    }

    const handleCopyLinkClick = () => {
        navigator.clipboard.writeText(lobbyLink).then( () => {
            console.log("Clipboard succesfully set")
        }, () => {
            console.log("Clipboard write failed")
        })
        showMessage("Copied Invitation-Link", "rightCondition")
            .then(r => console.log("Copied Room-ID"))
    }

    const handleShareOnClick = () => {
        console.log("klappt")
        if (navigator.share) {
            navigator.share({
                title: 'MiniGameCollection',
                text: 'Invitation-Link',
                url: lobbyLink,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    const handleReadyOnClick = () => {
        console.log("Player is ready!")
        socket.emit("playerReady", { lobbyId: lobbyId })
        const readyButton = document.getElementById("readyButton")
        setReady("I'm ready ✓")
        readyButton.setAttribute("disabled", "true")
    }

    useEffect(() => {
        socket.on("createdLobby", (message) => {
            console.log(message)
            saveLobbyId(message.lobbyId)
            setLobbyLink(props.startLobbyLink + message.lobbyId)
            socket.emit("joinLobby", { lobbyId: message.lobbyId, playerName: name })
        })

        socket.on("lobbyInfo", (message) => {
            console.log(message)
            setPlayer1(message.players[0])  //Player that created Lobby
            setPlayer2(message.players[1])  //Player that joined Lobby
        })

        return () => {
            socket.removeAllListeners("createdLobby");
            socket.removeAllListeners("lobbyInfo");
            console.log(socket)
        }
    },[])

    useEffect(() => {
        socket.on("playerReady", (message) => {
            console.log(message)
        })

        socket.on("startedGame", (message) => {
            console.log(message)
            saveGameBoard(message.gameBoard.fields)
            saveFirstPlayer(message.nextPlayer.name)
            savePlayers(message.gameBoard.players)
            navigate("../board")
        })

        return () => {
            //socket.removeAllListeners("playerReady")
            //socket.removeAllListeners("startedGame")
            console.log(socket)
        }
    },[])

    return(
        <div className="Lobby">
            <div className="name-container">
                <h3>{player1}</h3>
                <h4>vs</h4>
                <h3>{player2}</h3>
            </div>
            <div className="instruction">
                <h3>Instruction '{props.gameName}'</h3>
                <p>{instruction}</p>
            </div>
            <div className="button-bar">
                <div className="link-container">
                    <Button id={0} name={"Lobby-ID: " + lobbyId} onClick={handleCopyRoomIDClick} />
                    <Button id={1} name="Copy Link" onClick={handleCopyLinkClick} className="link-button" />
                    <Button name="QR-Code" onClick={() => setOpen(o => !o)} />
                    <Button name="Share" onClick={handleShareOnClick}/>
                </div>
                <div>
                    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                        <div className="modal">
                            <h4>Scan Me!</h4>
                            <QRCode value={lobbyLink} className={"qr-Code"} />
                            <Button name="Close" onClick={closeModal} className="popup-button" />
                        </div>
                    </Popup>
                </div>
                <Button name={ready} id="readyButton" onClick={handleReadyOnClick} className="ready-button" />
            </div>
        </div>
    )
}