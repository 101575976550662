import {Route, Routes} from "react-router-dom";
import Lobby from "../Lobby"
import MMYBoard from "./MMYBoard";
import JoinCreate from "../JoinCreate";
import Winner from "../Winner";
import {useEffect} from "react";
const io = require("socket.io-client")

export default function TTT() {
    const gameName = "Memory"
    const instruction =
        "On each turn, a player turns over any two cards (one at a time). " +
        "If both cards match, the cards are put aside and the player gets another turn. " +
        "When a player turns over two cards that do not match, those cards are turned face down again (in the same position) and it becomes the next player’s turn. " +
        "The person with the most pairs at the end of the game wins."
    const lobbyLink = "https://minigamecollection.de/memory?lobbyid="

    let socket = io.connect("https://minigamecollection.de:3000/memory")

    useEffect(() => {
        console.log("Socketconnection on!")
        socket.on("connect", () => {
            console.log("Connected to id: " + socket.id)
        })
        console.log(socket)

        socket.on("basicEmit", (message) => {
            console.log("basicEmit: " + message)
        })

        return () => {
            socket.disconnect()
        }
    }, [])

    return(
        <div>
            <Routes>
                <Route path="/" element={<JoinCreate socket={socket} gameName={gameName}/>} />
                <Route path="/lobby" element={<Lobby
                    socket={socket}
                    gameName={gameName}
                    instruction={instruction}
                    startLobbyLink={lobbyLink} />}
                />
                <Route path="/board" element={<MMYBoard socket={socket} />} />
                <Route path="/winner" element={<Winner socket={socket} />} />
            </Routes>
        </div>
    )
}