import "../css/Nav.css"
import Button from "./Button";
import {useNavigate} from "react-router-dom";

export default function(props) {

    const navigate = useNavigate()

    const handleHighscoreNav = () => {
        navigate("/highscores")
    }

    const handleAboutNav = () => {
        navigate("/about")
    }

    return(
        <div className="page-nav">
            <h1> <a href="/">MiniGame<wbr />Collection</a> </h1>
            <ul className="menu">
                <li> <Button name="Highscores" onClick={handleHighscoreNav} /> </li>
                <li> <Button name="About" onClick={handleAboutNav} /> </li>
            </ul>
        </div>
    )
}