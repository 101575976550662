export default (state, action) => {
    switch (action.type) {
        case "NAME_PLAYER":
            return{
                ...state,
                name: action.payload
            }
        case "SAVE_LOBBY_ID":
            return{
                ...state,
                lobbyId: action.payload
            }
        case "SAVE_GAME_BOARD":
            return{
                ...state,
                gameBoard: action.payload
            }
        case "SAVE_WINNER":
            return{
                ...state,
                winner: action.payload
            }
        case "SAVE_FIRST_PLAYER":
            return{
                ...state,
                firstPlayer: action.payload
            }
        case "SAVE_PLAYERS":
            return{
                ...state,
                players: action.payload
            }
        default:
            return state
    }
}