import "../../css/About.css"

export default function highscores() {

    return(
        <div className="about-container">
            <h2>About <wbr /> MiniGameCollection:</h2>
            <img src={require("../../resources/logo.jpg")} alt="logo" />
            <div className="about-info-container">
                <p>
                    MiniGameCollection is a web-based collection of various popular minigames
                    in which you can compete against other players.
                    <br />
                    <br />
                    MGC is a software-project that was created throughout the SS22 at the HdM Stuttgart
                    in the course of studies "Medieninformatik."
                </p>
            </div>
            <div className="creator">
                <h3>Creators:</h3>
                <div className="creator-names">
                    <p>Cedric Gottschalk</p>
                    <p>Mats Bayer</p>
                </div>
                <p><span className="mail-contact">E-Mail:</span> contact@minigamecollection.de</p>
            </div>
        </div>
    )
}