import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import GameSelection from "./components/GameSelection"
import Nav from "./components/Nav"
import TTT from "./components/TicTacToe/TTT";
import SS from "./components/SimonSays/SS";
import MMY from "./components/Memory/MMY";
import ComingSoon from "./components/ComingSoon/comingSoon";
import Highscores from "./components/Highscores/Highscores"
import About from "./components/About/About"
import {GlobalProvider} from "./context/GlobalState";

export default function App() {
  return (
     <div className="App">
      <GlobalProvider>
           <BrowserRouter>
             <div>
               <Nav />
               <Routes>
                 <Route path="/" element={ <GameSelection /> } />
                   <Route path="/ttt/*"                 element={ <TTT /> } />
                   <Route path="/simon-says/*"    element={ <SS /> } />
                   <Route path="/memory/*"              element={ <MMY /> } />
                   <Route path="/comingSoon/*"          element={ <ComingSoon /> } />
                   <Route path="/highscores/*"          element={ <Highscores /> } />
                   <Route path="/about"                 element={ <About /> } />
               </Routes>
             </div>
           </BrowserRouter>

      </GlobalProvider>
     </div>
  );
}