import "../css/JoinCreate.css"
import Button from "./Button";
import {useNavigate} from "react-router-dom";
import {useState, useContext, useEffect} from "react";
import {GlobalContext} from "../context/GlobalState";
import { showMessage } from "../message";
import Popup from "reactjs-popup";
import "../css/Popup.css"

export default function JoinCreate (props) {

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const { namePlayer, name, saveLobbyId } = useContext(GlobalContext)

    const [username, setUsername] = useState("")
    const [lobbyIdInput, setLobbyIdInput] = useState("")

    const socket = props.socket
    const gameName = props.gameName

    function isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    const handleNameOnChange = ({target}) =>{
        namePlayer(target.value)
    }

    const handleLobbyIdOnChange = ({target}) =>{
        setLobbyIdInput(target.value)
    }

    const handleJoinOnClick = () => {
        const name = document.getElementById("nameInput").value
        const lobbyId = document.getElementById("lobbyIdInput").value

        if (isBlank(name)) {
            showMessage("Please enter your name", "falseCondition")
                .then(r => console.log("Please enter your name"))
        }else if (isBlank(lobbyId)) {
            showMessage("Please enter a Lobby ID", "falseCondition")
                .then(r => console.log("Please enter a Lobby ID"))
        }else {
            socket.emit("joinLobby", { lobbyId: lobbyIdInput, playerName: name })
            if (lobbyId !== null) {

                socket.once("joinedLobby", (message) => {
                    console.log(message)

                    if (message.successful === true) {
                        saveLobbyId(message.lobbyId)
                        navigate("lobby")
                    }else {
                        showMessage("Lobby ID does not exist", "falseCondition")
                            .then(r => console.log("Lobby ID does not exist"))
                    }
                })
            }
        }
    }

    const handleCreateGameOnClick = () => {
        if (isBlank(name)) {
            showMessage("Please enter your name", "falseCondition")
                .then(r => console.log("Please enter your name"))
        } else {
            console.log(socket)
            socket.emit("createLobby")
            navigate("lobby")
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(document.location.search)
        const paramsLobbyId = params.get("lobbyid")
        if (paramsLobbyId !== null) {
            setLobbyIdInput(paramsLobbyId)
        }
    },[])

    return(
            <div id="Selection" className="Selection">
                <h3 className="gamename">{gameName}</h3>
                <input type="text" onChange={handleNameOnChange} id="nameInput" placeholder="Your Name" />
                <div className="join-container" id="join-container">
                    <input type="text" onChange={handleLobbyIdOnChange} id="lobbyIdInput" placeholder="Lobby ID" value={lobbyIdInput} />
                    <Button name="Join" onClick={handleJoinOnClick} className="joinCreate-Button" />
                </div>
                <Button name="Create Game" onClick={handleCreateGameOnClick} className="joinCreate-Button" />
                <Button name="How To Play" onClick={() => setOpen(o => !o)} className="howToPlay-Button" />
                <div>
                    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                        <div className="modal">
                            <h3>How To Play</h3>
                            <p>
                                If you want to create a game,
                                enter your name in the "Your Name" field and click the "Create Game" button.
                            </p>
                            <p>
                                If you want to join a game,
                                enter your name in the "Your Name" field and enter the lobby-ID in the "Lobby ID" field.
                                If you join via a link or a QR code, the lobby ID is already inserted.
                                Then click the "Join" button.
                            </p>
                            <p>
                                Now you will be redirected to the lobby screen.
                                There you will find instructions for the game and various ways to invite an opponent. <br />
                                When you are ready to play, press the "Ready" button.
                                When both players are ready, the gameboard opens and the game begins.
                            </p>
                            <Button name="Close" onClick={closeModal} className="popup-button" />
                        </div>
                    </Popup>
                </div>
            </div>
    )
}

