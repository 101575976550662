import "../../css/HighscoreTable.css"
import {useEffect, useState} from "react";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export default function HighscoreTableTTT(props) {

    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [items, setItems] = useState([])

    useEffect( () => {
        fetch("https://minigamecollection.de:3000/games/tictactoe/highscores")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true)
                    setItems(data)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }, [])

    if (error) {
        return <div>Error!</div>
    } else if (!isLoaded) {
        return <div className="loader" />
    } else {
        return(
            <div className="highscoreTable-container" >
                <table>
                    <thead>
                    <tr>
                        <th style={{textAlign: "left"}}>Name</th>
                        <th style={{textAlign: "right"}}>Wins</th>
                    </tr>
                    </thead>
                    <tbody className="table-body">
                    {
                        items.map( (item) => (
                            <tr key={generateUniqueID()}>
                                <td style={{textAlign: "left"}}>{item.name}</td>
                                <td style={{textAlign: "center"}}>{item.wins}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}