import "../../css/SSBoard.css"
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import { GlobalContext } from "../../context/GlobalState";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export default function SSBoard(props) {

    const navigate = useNavigate()

    const { lobbyId, saveGameBoard, saveWinner, firstPlayer, players } = useContext(GlobalContext)

    const [nextPlayer, setNextPlayer] = useState(firstPlayer)
    const socket = props.socket
    const [playerOne] = useState(players.playerOne)
    const [playerTwo] = useState(players.playerTwo)
    const [lifePlayerOne, setLifePlayerOne] = useState(playerOne.remainingLives)
    const [lifePlayerTwo, setLifePlayerTwo] = useState(playerTwo.remainingLives)

    useEffect(() => {
        socket.on("gameFinished", (message) => {
            //console.log(message)
            if (!message.draw) {
                saveWinner(message.winner.name)
                navigate("../winner")
            }else {
                console.log("Unentschieden")
                saveWinner(null)
                navigate("../winner")
            }
        })

        socket.on("gameBoardUpdate", function (message) {
            //console.log(message)
            saveGameBoard(message.gameBoard.fields)
            const xCoord = message.gameBoard.clickedField.coordinates.x
            const yCoord = message.gameBoard.clickedField.coordinates.y
            const filterdId = yCoord.toString() + xCoord.toString()
            const button = document.getElementById(filterdId)

            if (message.gameBoard.clickedField.correct) {
                button.animate([
                        { transform: "scale(1)" },
                        { transform: "scale(0.85)" },
                        { transform: "scale(1)" }
                    ],
                    {
                        duration: 600,
                        iterations: 1,
                        delay: 0
                    })
            } else {
                button.setAttribute("class", "falseDecision")
                setTimeout( () => {
                    button.removeAttribute("falseDecision")
                    button.setAttribute("class", "farbe")
                }, 1000)
                if (message.gameBoard.playerInfo.socketId === playerOne.socketId) {
                    setLifePlayerOne(message.gameBoard.playerInfo.remainingLives)
                } else {
                    setLifePlayerTwo(message.gameBoard.playerInfo.remainingLives)
                }
            }
        })

        socket.on("nextTurnInfo", (message) => {
            //console.log(message)
            setNextPlayer(message.nextPlayer.name)
        })

        return () => {
            socket.removeAllListeners("gameFinished")
            socket.removeAllListeners("gameBoardUpdate")
            socket.removeAllListeners("nextTurnInfo")
        }
    },[])

    const handleOnClick = (event) => {
        //console.log("Sende Eingabe!")
        socket.emit("makeTurn", {
            lobbyId: lobbyId,
            turnInfo: {
                coordinates: {
                    x: parseInt(event.target.getAttribute("x")),
                    y: parseInt(event.target.getAttribute("y"))
                }
            }
        })
    }

    return(
        <div className="SSBoard">
            <h2>It's {nextPlayer} Turn!</h2>
           <div className="LifeContainer">
               <div className="LifeContainerPlayers">
                   <h3>{playerOne.name}: </h3>
                   <div className="Lifes">
                       {[...Array(lifePlayerOne)].map( () => {
                           return <img key={generateUniqueID()}
                                       src={require('../../resources/heart.png')}
                                       alt="abc"
                           />
                       })}
                   </div>
               </div>
               <div className="LifeContainerPlayers">
                   <h3>{playerTwo.name}: </h3>
                   <div className="Lifes">
                       {[...Array(lifePlayerTwo)].map( () => {
                           return <img key={generateUniqueID()}
                                       src={require('../../resources/heart.png')}
                                       alt="abc"
                           />
                       })}
                   </div>
               </div>
           </div>
            <div className="SSButtons">
                <Button id="00" y={0} x={0}  onClick={handleOnClick} className="farbe" />
                <Button id="01" y={0} x={1}  onClick={handleOnClick} className="farbe" />
                <Button id="02" y={0} x={2}  onClick={handleOnClick} className="farbe" />
                <Button id="10" y={1} x={0}  onClick={handleOnClick} className="farbe" />
                <Button id="11" y={1} x={1}  onClick={handleOnClick} className="farbe" />
                <Button id="12" y={1} x={2}  onClick={handleOnClick} className="farbe" />
                <Button id="20" y={2} x={0}  onClick={handleOnClick} className="farbe" />
                <Button id="21" y={2} x={1}  onClick={handleOnClick} className="farbe" />
                <Button id="22" y={2} x={2}  onClick={handleOnClick} className="farbe" />
            </div>
        </div>
    )
}