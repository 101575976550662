import "../css/Footer.css"

export default function (props) {

    return(
        <div className="footer-container">
            <footer>
                <p>Contact: contact@minigamecollection.de</p>
            </footer>
        </div>
    )
}