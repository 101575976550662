import "../../css/TTTBoard.css"
import {useContext, useEffect, useState} from "react";
import Button from "../Button";
import {GlobalContext} from "../../context/GlobalState";
import {useNavigate} from "react-router-dom";

export default function TTTBoard (props) {

    const navigate = useNavigate()

    const { lobbyId, gameBoard, saveGameBoard,saveWinner, firstPlayer } = useContext(GlobalContext)

    const [nextPlayer, setNextPlayer] = useState(firstPlayer)
    const socket = props.socket

    useEffect(() => {
        socket.on("gameFinished", (message) => {
            //console.log(message)
            if (!message.draw) {
                saveWinner(message.winner.name)
                navigate("../winner")
            }else {
                console.log("Unentschieden")
                saveWinner(null)
                navigate("../winner")
            }
        })

        socket.on("gameBoardUpdate", (message) => {
            //console.log(message)
            saveGameBoard(message.gameBoard.fields)
        })

        socket.on("nextTurnInfo", (message) => {
            //console.log(message)
            setNextPlayer(message.nextPlayer.name)
        })

        return () => {
            socket.removeAllListeners("gameFinished")
            socket.removeAllListeners("gameBoardUpdate")
            socket.removeAllListeners("nextTurnInfo")
        }
    },[])

    const handleOnClick = (event) => {
        //console.log("Sende diese Nachricht")
        //console.log(lobbyId)
        socket.emit("makeTurn", {
            lobbyId: lobbyId,
            turnInfo: {
                coordinates: {
                    x: parseInt(event.target.getAttribute("x")),
                    y: parseInt(event.target.getAttribute("y"))
                }
            }
        })
    }

    return(
        <div className="TTTBoard">
            <h2>It's {nextPlayer} Turn!</h2>
            <div className="TTTButtons">
                <Button y={0} x={0} className={gameBoard[0][0]} onClick={handleOnClick} />
                <Button y={0} x={1} className={gameBoard[0][1]} onClick={handleOnClick}  />
                <Button y={0} x={2} className={gameBoard[0][2]} onClick={handleOnClick}  />
                <Button y={1} x={0} className={gameBoard[1][0]} onClick={handleOnClick}  />
                <Button y={1} x={1} className={gameBoard[1][1]} onClick={handleOnClick}  />
                <Button y={1} x={2} className={gameBoard[1][2]} onClick={handleOnClick}  />
                <Button y={2} x={0} className={gameBoard[2][0]} onClick={handleOnClick}  />
                <Button y={2} x={1} className={gameBoard[2][1]} onClick={handleOnClick}  />
                <Button y={2} x={2} className={gameBoard[2][2]} onClick={handleOnClick}  />
            </div>
        </div>
    )
}