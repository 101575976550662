import "../css/GameSelection.css"
import Button from "./Button";
import {useNavigate} from "react-router-dom";
import Footer from "./Footer";

export default function() {

    const navigate = useNavigate()

    const handleOnClick = (event) => {
       const game = event.target.id
        //console.log(game)
        switch (game) {
            case "ticTacToe":
                navigate("/ttt")
                break;
            case "memory":
                navigate("/memory")
                break;
            case "simon-says":
                navigate("/simon-says")
                break;
            case "comingSoon":
                navigate("/comingSoon")
                break;
        }
    }

    return(
        <div className="gameSelection-container">
            <h2>Choose a game<wbr /> you want to play!</h2>
            <div className="gameSelection">
                <Button id="ticTacToe"  onClick={handleOnClick} className="gameButton" name="Tic Tac Toe" />
                <Button id="memory"     onClick={handleOnClick} className="gameButton" name="Memory" />
                <Button id="simon-says" onClick={handleOnClick} className="gameButton" name="Simon Says" />
                <Button id="comingSoon" onClick={handleOnClick} className="gameButton" name="Coming Soon" />
            </div>
            <Footer />
        </div>
    )
}
