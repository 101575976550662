import "../../css/Highscores.css"
import Button from "../Button";
import HighscoreTableTTT from "./HighscoreTableTTT";
import HighscoreTableMMY from "./HighscoreTableMMY";
import HighscoreTableSS from "./HighscoreTableSS";
import { Route, Routes, useNavigate } from "react-router-dom";

export default function Highscores() {

    const navigate = useNavigate()

    const handleToTTTHighscore = () => {
        navigate("ttt")
    }

    const handleToMMYHighscore = () => {
        navigate("memory")
    }

    const handleToSSHighscore = () => {
        navigate("simon-says")
    }

    return(
        <div className="highscores-container">
            <div className="info-container" >
                <h2>Highscores:</h2>
                <ul className="buttonBar">
                    <li> <Button name="Tic Tac Toe" onClick={handleToTTTHighscore} /> </li>
                    <li> <Button name="Memory" onClick={handleToMMYHighscore} /> </li>
                    <li> <Button name="Simon Says" onClick={handleToSSHighscore} /> </li>
                </ul>

            </div>
            <div className="table-container">
                <Routes>
                    <Route path="/ttt" element={ <HighscoreTableTTT /> } />
                    <Route path="/memory" element={ <HighscoreTableMMY /> } />}
                    <Route path="/simon-says" element={ <HighscoreTableSS />}  />
                </Routes>
            </div>
        </div>
    )
}