const wait = (seconds) => {
    return new Promise (
        resolve => setTimeout(resolve, 1000 * seconds)
    )
}

export async function showMessage (text, messageType) {
    const falseCondition = document.createElement("p")
    falseCondition.setAttribute("class", "falseCondition")
    falseCondition.setAttribute("class", messageType)
    falseCondition.innerHTML = text
    document.getElementById("root").appendChild(falseCondition)
    await wait (2.5);
    falseCondition.remove()
    //TODO: alle meldungen sind übereinander -> fixen
}