import "../../css/MMYBoard.css"
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../context/GlobalState";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export default function MMYBoard(props) {

    const navigate = useNavigate()

    const { lobbyId } = useContext(GlobalContext)
    const { gameBoard } = useContext(GlobalContext)
    const { saveWinner } = useContext(GlobalContext)
    const { firstPlayer } = useContext(GlobalContext)

    const [nextPlayer, setNextPlayer] = useState(firstPlayer)
    const socket = props.socket

    const handleOnClick = (event) => {
        //console.log("Sende diese Nachricht")
        //console.log(lobbyId)
        socket.emit("makeTurn", {
            lobbyId: lobbyId,
            turnInfo: {
                coordinates: {
                    x: parseInt(event.target.getAttribute("x")),
                    y: parseInt(event.target.getAttribute("y"))
                }
            }
        })
    }

    const gameBoardToButtons = gameBoard.map( (item, indexOne) => (
        item.map( (subItem, indexTwo) => (
            <button
                y={indexOne}
                x={indexTwo}
                key={generateUniqueID()}
                className={subItem.id}
                isturned={subItem.isTurned.toString()}
                onClick={handleOnClick}
            >
            </button>
        ))
    ))
    const [gameBoardHTML, setGameBoardHTML] = useState(gameBoardToButtons)

    useEffect(() => {
        socket.on("gameFinished", (message) => {
            //console.log(message)
            if (!message.draw) {
                saveWinner(message.winner.name)
                navigate("../winner")
            }else {
                console.log("Unentschieden")
                saveWinner(null)
                navigate("../winner")
            }
        })

        socket.on("gameBoardUpdate", (message) => {
            //console.log(message)
            //saveGameBoard(message.gameBoard.memoryCards)
            let buttonTable = message.gameBoard.fields.map( (item, indexOne) => (
                item.map( (subItem, indexTwo) => (
                    <button
                        y={indexOne}
                        x={indexTwo}
                        key={generateUniqueID()}
                        className={subItem.id}
                        isturned={subItem.isTurned.toString()}
                        onClick={handleOnClick}
                    >
                    </button>
                ))
            ))
            setGameBoardHTML(buttonTable)
        })

        socket.on("nextTurnInfo", (message) => {
            console.log(message)
            setNextPlayer(message.nextPlayer.name)
        })

        return () => {
            socket.removeAllListeners("gameFinished")
            socket.removeAllListeners("gameBoardUpdate")
            socket.removeAllListeners("nextTurnInfo")
        }
    },[])

    return(
        <div className="MMYBoard">
            <h2>{nextPlayer} ist am Zug!</h2>
            <div className="MMYButtons">
                {gameBoardHTML}
            </div>
        </div>
    )
}