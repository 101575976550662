import "../css/Winner.css"
import Button from "./Button";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../context/GlobalState";

export default function Winner (props) {

    const socket = props.socket

    const { lobbyId, winner, saveFirstPlayer, savePlayers } = useContext(GlobalContext)

    const [winnerMessage, setWinnerMessage] = useState(null)
    const [rematch, setRematch] = useState("Rematch?")

    const handleRematchOnClick = () => {
        socket.emit("startNewGame", { lobbyId: lobbyId })
        saveFirstPlayer(null)
        savePlayers( {} )
        const rematchButton = document.getElementById("rematchButton")
        setRematch("Rematch ✓")
        rematchButton.setAttribute("disabled", "true")
    }

    const handleMainMenuOnClick = () => {
        window.location.replace("https://minigamecollection.de")
    }

    useEffect(() => {
        if (winner != null) {
            setWinnerMessage(winner + " won!")
        }else {
            setWinnerMessage("It's a draw")
        }
    },[])

    return(
        <div className="winner-container" >
            <h2>{winnerMessage}</h2>
            <img src={require("../resources/trophy.png")}/>
            <Button name={rematch} id="rematchButton" className="rematchButton" onClick={handleRematchOnClick} />
            <Button name="Main Menu" onClick={handleMainMenuOnClick} />
        </div>
    )
}