import "../../css/ComingSoon.css"
import { Link } from "react-router-dom";

export default function ComingSoon() {

    return(
        <div className="comingSoon-container">
            <h2>More games are coming soon!</h2>
            <p>If you have any suggestions or other comments, please contact us.</p>
            <Link
                to='mailto:contact@minigamecollection.de'
                onClick={(e) => {
                    window.location.href = "mailto:contact@minigamecollection.de";
                    e.preventDefault();
                }}
            >
                Send an E-Mail
            </Link>
        </div>
    )
}
